<template>
  <div class="error-box-403">
    <div>
      <h1>403</h1>
      <h3>
        {{ $t('errorLog.errorTypes.403') }}
        <v-btn text @click="logout">Abmelden</v-btn>
      </h3>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService.js';

export default {
  mounted() {
    this.authService = new AuthService();
  },

  methods: {
    async logout() {
      if (this.currentOrganization) {
        this.$organizationHub.leaveOrganization(this.currentOrganization.id);
      }

      await this.authService.logout();
    }
  }
};
</script>

<style lang="scss">
body .error-box-403 {
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 100pt;
  }

  h3 {
    display: inline;
  }
}
</style>
